<template>
    <div>
        <h1>TemplateDesign</h1>
    </div>
</template>

<script>
export default {
    name: 'TemplateDesign'
};
</script>

<style lang="scss" scoped></style>
