<template>
    <div>
        <div class="col-lg-12">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingPage.pageType') }} </label>
                    <div class="col-sm-9">
                        {{ dataForm.setting_page.page_type?.name }}
                    </div>
                </div>
                <h5 class="my-3">{{ dataForm.setting_page.page_type?.name ?? '' }}</h5>
                <div v-if="dataForm.setting_page.page_type?.name" class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-9">
                        <div v-for="(master_page, idx) in dataForm.setting_page.pages" :key="idx">
                            <div class="form-group row">
                                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ master_page.title }}</label>
                            </div>
                            <div v-for="(page, k) in master_page.page_child" :key="k" class="form-group row">
                                <label class="col-sm-10 text-nowrap" style="min-width: 150px">{{ page.name_page }}</label>
                                <div class="col-sm-2 d-flex flex-wrap">
                                    <button
                                        type="button"
                                        class="btn btn-outline-info btn-sm rounded-circle mr-3 mb-2"
                                        style="width: 30px; height: 30px"
                                        @click="handleEditFormInput(page, idx, k)"
                                    >
                                        <i class="fa fa-info"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <ModalEditPage :configModal="configModalEditPage" />
    </div>
</template>

<script>
import { configModalAddPage, configModalEditPage } from './constants';

import ModalEditPage from './modal-edit-page.vue';

export default {
    name: 'SettingPage',
    components: {
        ModalEditPage
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            configModalAddPage: configModalAddPage,
            configModalEditPage: configModalEditPage
        };
    },
    methods: {
        handleEditFormInput(obj, idx, k) {
            this.configModalEditPage.model = true;
            this.configModalEditPage.id = `edit_page-${idx}_${k}`;
            this.configModalEditPage.title = obj.name_page;
        }
    },
    mounted() {
        this.dataForm.setting_page.page_type = {
            id: 1,
            name: 'A'
        };
    }
};
</script>

<style lang="scss" scoped></style>
