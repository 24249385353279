import i18n from '@/i18n';

const configTable = {
    headers: [
        {
            label: i18n.t('managerForm.settingSerialCode.serialCode')
        },
        {
            label: i18n.t('managerForm.settingSerialCode.numberLimit')
        },
        {
            label: i18n.t('managerForm.settingSerialCode.numberUsed')
        }
    ]
};

export { configTable };
