<template>
    <div>
        <ModalCommon :id="`${configModal?.id}`" :config="configModal" @close="handleClose()" :isView="true">
            <!-- block -->
            <div class="form-group row flex-nowrap">
                <div class="col-sm-12">
                    <div class="white_wrap mb-3">
                        <div class="row" style="gap: 12px">
                            <p class="btn mb-0 h-100" :class="activePreview == 'PC' ? 'btn-outline-success' : ''" @click="handleSwitchDevice('PC')">
                                <i class="fa fa-laptop"></i> PC
                            </p>
                            <p
                                class="btn mb-0 h-100"
                                :class="activePreview == 'Mobile' ? 'btn-outline-success' : ''"
                                @click="handleSwitchDevice('Mobile')"
                            >
                                <i class="fa fa-mobile"></i> Mobile
                            </p>
                            <div class="ml-auto">
                                <div class="d-flex align-items-center justify-content-end mr-3 mb-2">
                                    <InputText
                                        class="mr-1"
                                        style="max-width: 100px"
                                        :model.sync="pixel_input"
                                        :config="{
                                            isNumber: false,
                                            error: false
                                        }"
                                        @change="handleInputPixel"
                                    />
                                    <div>px</div>
                                </div>
                                <div style="font-size: 12px">プレビューの横幅には300～2000の整数を入力してください。</div>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">画面サイズは横幅{{ pixel_action ? `${pixel_action}px` : '100%' }}で表示されています</div>
                    <div class="ifame_page d-flex justify-content-center">
                        <iframe
                            :src="`${originPath}/manager-form/iframe-page`"
                            :width="`${pixel_action ? pixel_action : '100%'}`"
                            height="575"
                            frameborder="0"
                            allowfullscreen
                            scrolling="auto"
                        ></iframe>
                    </div>
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import ModalCommon from '@/components/Modal/main.vue';

import { InputText } from '@/components/Input';

export default {
    name: 'ModalEditPage',
    components: {
        ModalCommon,
        InputText
    },
    props: {
        configModal: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            activePreview: 'PC',
            pixel_input: '',
            pixel_action: '',
            originPath: window.location.origin
        };
    },
    methods: {
        handleClose() {
            this.configModal.model = false;
            this.activePreview = 'PC';
            this.pixel_input = '';
            this.pixel_action = '';
        },

        handleSwitchDevice(device) {
            this.activePreview = device;
            this.pixel_input = device == 'PC' ? '' : device == 'Mobile' ? '400' : '';
            this.pixel_action = this.pixel_input;
        },

        handleInputPixel(value) {
            this.activePreview = '';
            if (value < 300) {
                this.pixel_input = 300;
            } else if (value > 2000) {
                this.pixel_input = 2000;
            }
            this.pixel_action = this.pixel_input;
        }
    }
};
</script>

<style lang="scss" scoped>
.btn-outline-success:hover {
    color: #1cbb8c;
    border-color: #1cbb8c;
    background-color: #fff;
}
</style>
