<template>
    <div>
        <div class="col-lg-12">
            <div class="form-group row mb-3">
                <label class="col-sm-3 text-nowrap" style="min-width: 150px">{{ $t('managerForm.settingSerialCode.serialCode') }}</label>
                <div class="col-sm-9">
                    <UploadFileCommon @dataFile="handleSerialCode" title="ファイルアップロード" :id="`SerialCode`" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3 text-nowrap" style="min-width: fit-content">{{
                    $t('managerForm.settingSerialCode.applicationCommonMail')
                }}</label>
                <div class="col-sm-9">
                    <InputCheckBox
                        class="mr-4 py-1"
                        :model.sync="dataForm.mail_common"
                        :id="`check_mail-common`"
                        :value="false"
                        :label="``"
                        :disabled="true"
                    />
                </div>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered custom__table--sticky table-box">
                    <thead>
                        <tr>
                            <th v-for="(item, k) in configTable.headers" :key="k">
                                <div class="d-flex align-items-center">
                                    {{ item.label }}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(seriCode, idx) in dataForm.serial_codes" :key="idx">
                            <td>
                                <div class="row-data-small" v-b-tooltip.hover :title="seriCode?.seri_code">
                                    {{ seriCode?.seri_code ?? '' }}
                                </div>
                            </td>
                            <td>
                                <div class="row-data-small" v-b-tooltip.hover :title="seriCode?.number_limit">
                                    {{ seriCode?.number_limit ?? '' }}
                                </div>
                            </td>
                            <td>
                                <div class="row-data-small" v-b-tooltip.hover :title="seriCode?.number_used">
                                    {{ seriCode?.number_used ?? '' }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import UploadFileCommon from '@/components/Uploads/Files/main.vue';

import { InputCheckBox } from '@/components/Input';
import { configTable } from './constants';

export default {
    name: 'SettingSerialCode',
    components: {
        UploadFileCommon,
        InputCheckBox
    },
    props: {
        dataForm: {
            type: Object,
            required: true,
            default: () => null
        }
    },
    data() {
        return {
            configTable: configTable
        };
    },
    methods: {
        handleSerialCode(file) {
            this.formData = file;
        }
    }
};
</script>

<style lang="scss" scoped></style>
